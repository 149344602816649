import { Drawer, List, ListItem, ListItemText, Typography, makeStyles, } from '@material-ui/core';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from 'react';

const DrawerNavigation = ({ items = [], anchor = 'left', open, onClose }) => {

    const classes = useStyles();

    return <Drawer anchor={anchor} open={open} onClose={onClose} >
        <List>
            {
                items.length > 0 && items.map((item, index) => {
                    return <ListItem key={index}>
                        <ListItemText
                            primary={
                                <Typography
                                component="span"
                                variant="body">
                                <AnchorLink to={item.href} className={classes.link}>
                                    <span>{item.name}</span>
                                </AnchorLink>
                                </Typography>
                            }
                        />
                    </ListItem>
                }) 
                
            }
        </List>
    </Drawer>

}

const useStyles = makeStyles((theme) => ({
    link:{
        color:'#06183c',
        transition:'.5 ease',
        '&:hover':{
            color:'#ff8226'        
        },
    }
})) 

export default DrawerNavigation;
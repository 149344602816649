import Slide1 from '../images/Slide1.png';
import Slide2 from '../images/Slide2.png';
import Slide3 from '../images/Slide3.png';
import Slide4 from '../images/Slide4.png';

export const ImgSlide = [
    {
        id:1,
        src:Slide1,
        title:'VERTICAL',
    },
    {
        id:2,
        src:Slide2,
        title:'INDUSTRIAL',
    },
    {
        id:3,
        src:Slide3,
        title:'COMERCIAL',
    },
    {
        id:4,
        src:Slide4,
        title:'URBANO',
    },
];
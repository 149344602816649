import React, { useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Item = ({ image, value }) => {
    
    const clasess = useStyles();
    
    return (
        <div style={{ height:'400px', marginTop: 10 }}>
            <img src={image.src} alt={image.alt} width={200}/>
            {
                image.id === value && <>
                <div className={clasess.containerItem}>
                    <div className={clasess.verticalLine}></div>
                    <h3 className={clasess.title}>{image?.title}</h3>
                    <a href={image?.file} target="_blank">
                        <button className={clasess.button}>DESCARGAR FICHA</button>
                    </a>
                </div>
                </>
            }
        </div>
    )
}

const SlideShowProducts = ({srcImgs = []}) => {

    const [active, setActive] = useState(2);
    const [isMobile, setIsMobile] = useState(1);
    const clasess = useStyles();

    useEffect(() => {
        setIsMobile(document.documentElement.clientWidth <= 769 ? 1 : 4)
    },[]);

    return (
        <div id="productos">
            <Carousel 
        arrows
        arrowLeft={<ChevronLeftIcon className={clasess.Icon}/>}
        arrowRight={<ChevronRightIcon className={clasess.Icon}/>}
        addArrowClickHandler
        clickToChange
        centered
        onChange={(value) => setActive(value)}
        slidesPerPage={isMobile}
        value={active}
          >
        {
            srcImgs.map((image) => (
                <Item image={image} value={active}/>
            ))
        }
    </Carousel>
        </div>
    );
}

const useStyles = makeStyles({
    containerItem:{
        position:'absolute'
    },
    verticalLine:{
        position:'relative',
        height:'40px',
        left:'66%',
        borderLeft:'4px solid #06183c'
    },
    title:{
        textAlign: 'center',
        position:'relative',
        left:'18%',
        marginTop:'0.5rem',
        marginBottom:'0.5rem',
        width:'150px',
    },
    button:{
        backgroundColor:'white',
        borderRadius:'5rem',
        color:'#06183c',
        fontSize:'10pt',
        position: 'relative',
        left:'21%'
    },
    Icon:{
        fontSize: '32pt',
        cursor: 'pointer'
    }
    
});

export default SlideShowProducts;
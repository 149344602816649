import ecoslim from '../images/producto1.png';
import luminarioSolar106W from '../images/producto2.png';
import producto3 from '../images/producto3.png';
import producto4 from '../images/producto4.png';
import producto5 from '../images/producto5.png';
import aba from '../images/aba.png';
import buroLed from '../images/buroLed.png';
import downlight from '../images/downlight.png';
import ecoslim15w from '../images/ecoslim15w.png';
import empotrado12w from '../images/empotrado12w.png';
import florburo from '../images/florburo.png';
import highdrop from '../images/highdrop.png';
import highdrop170w240w300w from '../images/HIGHDROP170W240W300W.png';
import kitlamparagermicidauvc from '../images/kitlamparagermicidauvc.png';
import kuisobrepuesto from '../images/KUISOBREPUESTO.png';
import lamparagermicidavertica from '../images/LAMPARAGERMICIDAVERTICA.png';
import LuminarioSolar40W60W80W from '../images/LuminarioSolar40W60W80W.png';
import mp01home from '../images/MP01HOME.png';
import nauticoled15w from '../images/NauticoLED15W.png';
import oysterapruebadevapor from '../images/OYSTERAPRUEBADEVAPOR.png';
import panel60x60 from '../images/PANEL60X60.png';
import pluvi from '../images/Pluvi.png';
import slimpanel from '../images/SLIMPANEL.png';
import sylproofled2mp from '../images/SYLPROOFLED2MP.png';
import tiraled4wxm from '../images/TIRALED4WXM.png';
import towerlight from '../images/TOWERLIGHT.png';
import tunnelled from '../images/TUNNELLED.png';
import tubular from '../images/Tubular.png';
import highdrop195w from '../images/HIGHDROP195W.png';
import twoeye1twoeye2 from '../images/TWOEYE_1TWOEYE_2.png';
import highdropshort45w from '../images/HIGHDROPSHORT45W.png';
import highdrop4x18 from '../images/HIGHDROP4X18.png';
import highdrop45w from '../images/HIGHDROP45W.png';
import highdrop300w from '../images/HIGHDROP300W.png';
import ecoslimPdf from '../pdf/ECOSLIM.pdf';
import abaPdf from  '../pdf/ABACARCASA.pdf';
import altu2Pdf from  '../pdf/Altu2.pdf';
import buroLedPdf from '../pdf/BUROLED.pdf';
import downlightPdf from '../pdf/Downlight.pdf';
import ecoslim15wPdf from '../pdf/ECOSLIM15W.pdf';
import ecoslim18wPdf from '../pdf/ECOSLIM18W.pdf';
import empotrado12wBotePdf from '../pdf/EMPOTRADOBOTE12W.pdf';
import florburoPdf from '../pdf/FlorBuroLED.pdf';
import highdrop80wpdf from '../pdf/HIGHDROP80W.pdf';
import highdrop90w70wPdf from '../pdf/HIGHDROP90W70W.pdf';
import highdrop170w240w300wpdf from '../pdf/HIGHDROP170W240W300W.pdf';
import kitlamparagermicidauvcpdf from '../pdf/KITLAMPARAGERMICIDAUVC.pdf';
import kuisobrepuestopdf from '../pdf/KUISOBREPUESTO.pdf';
import lamparagermicidaverticapdf from '../pdf/LAMPARAGERMICIDAVERTICAL.pdf';
import luminarisolar106wpdf from '../pdf/LuminariSolar106W.pdf';
import LuminarioSolar40W60W80wpdf from '../pdf/LuminarioSolar40W60W80W.pdf';
import mp01homepdf from '../pdf/MP01HOME.pdf';
import nauticoled15wpdf from '../pdf/NauticoLED15W.pdf';
import oysterapruebadevaporpdf from '../pdf/OYSTERAPRUEBADEVAPOR.pdf';
import panel60x60pdf from '../pdf/PANEL60X60.pdf';
import panelled2x2pdf from '../pdf/PANELLED2X2.pdf';
import pluvipdf from '../pdf/Pluvi.pdf';
import slimpanelpdf from '../pdf/SLIMPANEL.pdf';
import sylproofled2mppdf from '../pdf/SYLPROOFLED2MP.pdf';
import tiraled4wxmpdf from '../pdf/TIRALED4WXM.pdf';
import towerlightpdf from '../pdf/TOWERLIGHT.pdf';
import tubularpdf from '../pdf/Tubular.pdf';
import tunnelledpdf from '../pdf/TUNNELLED.pdf';
import twoeye1twoeye2pdf from '../pdf/TWOEYE_1TWOEYE_2.pdf';
import highdrop195wpdf from '../pdf/HIGHDROP195W.pdf';
import highdrop240wpdf from '../pdf/HIGHDROP240W.pdf';
import highdropshort45wpdf from '../pdf/HIGHDROPSHORT45W.pdf';
import highdrop45wpdf from '../pdf/HIGHDROP45W.pdf';
import highdrop300wpdf from '../pdf/HIGHDROP300W.pdf';
import highdrop4x18pdf from '../pdf/HIGHDROP4X18.pdf';

const products = [
    {
        id: 0,
        alt:'producto 1',
        src: highdrop45w,
        title: 'HIGHDROP 45W',
        file: highdrop45wpdf,
    },
    {
        id: 1,
        alt:'producto 1',
        src: highdrop,
        title: 'HIGHDROP 80W',
        file: highdrop80wpdf,
    },
    {
        id: 2,
        alt:'producto 1',
        src: highdrop,
        title: 'HIGHDROP 90W,70W',
        file: highdrop90w70wPdf,
    },
    {
        id: 3,
        alt:'producto 1',
        src: highdrop170w240w300w,
        title: 'HIGHDROP 170W, 240W, 300W',
        file: highdrop90w70wPdf,
    },
    {
        id: 4,
        alt:'producto 1',
        src: highdrop195w,
        title: 'HIGHDROP 195W',
        file: highdrop195wpdf,
    },
    {
        id: 5,
        alt:'producto 1',
        src: ecoslim,
        title: 'ECOSLIM 9W',
        file: ecoslimPdf,
    },
    {
        id: 6,
        alt:'producto 1',
        src: ecoslim15w,
        title: 'ECOSLIM 15W',
        file: ecoslim15wPdf,
    },
    {
        id: 7,
        alt:'producto 1',
        src: ecoslim,
        title: 'ECOSLIM 18W',
        file: ecoslim18wPdf,
    },
    {
        id: 8,
        alt:'producto 1',
        src: twoeye1twoeye2,
        title: 'TWO-EYE 1 TWO-EYE 2',
        file: twoeye1twoeye2pdf,
    },
    {
        id: 9,
        alt:'producto 1',
        src: empotrado12w,
        title: 'EMPOTRADO BOTE 12W',
        file: empotrado12wBotePdf,
    },
    {
        id: 10,
        alt:'producto 1',
        src: panel60x60,
        title: 'PANEL LED 2X2',
        file: panelled2x2pdf,
    },
    {
        id: 11,
        alt:'producto 1',
        src: tiraled4wxm,
        title: 'TIRA LED 4WXM',
        file: tiraled4wxmpdf,
    },
    {
        id: 12,
        alt:'producto 1',
        src: LuminarioSolar40W60W80W,
        title: 'LUMINARIO SOLAR 40W,60W,80W',
        file: LuminarioSolar40W60W80wpdf,
    },
    {
        id: 13,
        alt:'producto 1',
        src: luminarioSolar106W,
        title: 'LUMINARIO SOLAR 106W',
        file: luminarisolar106wpdf,
    },
    {
        id: 14,
        alt:'producto 1',
        src: lamparagermicidavertica,
        title: 'LAMPARA GERMICIDA VERTICAL',
        file: lamparagermicidaverticapdf,
    },
    {
        id: 15,
        alt:'producto 1',
        src: kitlamparagermicidauvc,
        title: 'KIT LAMPARA GERMICIDA UVC',
        file: kitlamparagermicidauvcpdf,
    },
]


export default products;
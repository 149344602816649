import * as React from "react"
import { ThemeProvider } from '@material-ui/core/styles'
import Navigation from "../templates/Navigation"
import SlideShow from "../templates/SlideShow"
import itemsNavigate from "../constants/ItemsNavigation"
import 'normalize.css';
import '../css/index.scss';
import theme from "../theme";
import { Container } from "@material-ui/core"
import AboutUs from "../templates/AboutUs"
import aboutUs from "../constants/aboutUs"
import Services from "../templates/Services"
import services from "../constants/services"
import logoMP from "../images/logoMP.png"
import Contact from "../templates/Contact"
import SlideShowProducts from "../templates/SlideShowProducts"
import products from '../constants/products'
import SlideProject from "../templates/SlideProjects"
import { projects } from "../constants/projects"

// markup
const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Navigation 
            title="Home Page"
            logosrc={logoMP}
            position="fixed" 
            items={itemsNavigate} 
            color={'transparent'}
            elevation={0}/>
            <SlideShow/>
            <Container fixed>
              <AboutUs leftTitle="Quiénes somos" title={aboutUs.title} subtitle={aboutUs.subtitle} description={aboutUs.fragmentDescription}/>
              <Services services={services} />
              <SlideProject projects={projects}/>
              <SlideShowProducts srcImgs={products}/>
            </Container>
            <Contact/>
    </ThemeProvider>
  )
}

export default IndexPage

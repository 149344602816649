import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { PRIMARY_COLOR } from '../constants/appConstant';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { img6years } from '../constants/aboutUs';


const AboutUs = ({ leftTitle, title, subtitle, description }) => {

    const clasess = useStyles();

    return(
        <div 
        id="quienes-somos">
            <Grid container 
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        style={{ minHeight:'500px'}}
        >
            <Grid item sm={2} className={clasess.sectionDesktop} >
                <p className={clasess.titleLeft}>{leftTitle}</p>
            </Grid>
            <Grid item sm={5} xs={12} style={{ textAlign:'center', marginTop:'2rem'}} >
                <Carousel
                  infinite
                  autoPlay={5000}
                  animationSpeed={2000}
                >
                    {
                        img6years.map((img, index) => (
                            <img src={img.src} alt={img.alt} key={img.id} width={300}/>
                        ))
                    }
                </Carousel>
            </Grid>
            <Grid item sm={5} xs={12} className={clasess.container}>
              <h1>{title}</h1>
              <h3><strong>{subtitle}</strong> </h3>
              <p>Nuestra marca es comercializada por exclusivos distribuidores
                   a nivel mundial. Por lo que nuestro servicio ha llegado a 
                   posicionarse de manera importante para brindar la mejor alternativa 
                   personalizada a cada cliente.
              </p> 
              <p>Tenemos más de 6 años de experiencia en el mercado, en colaboración 
                    con diversos proyectos de alto nivel en impacto y calidad. Abarcando 
                    el ámbito industrial, comercial, vertical y urbano.
              </p>
            </Grid>
          </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container:{
        marginLeft:'1rem',
        marginRight: '1rem',

        [theme.breakpoints.up('md')]:{
            marginLeft:0,
            marginRight:0
        },
    },

    sectionDesktop: {
        display:'none',
        [theme.breakpoints.up('md')]:{
            display:'flex'
        },
    },
    titleLeft:{
        transform: 'rotate(-90deg)',
        color:PRIMARY_COLOR,
        textTransform:'uppercase',
    },
}))

export default AboutUs;
import React, { useEffect, useState } from 'react';
import '../css/slideshow.scss';
import Carousel from 'react-material-ui-carousel';
import { ImgSlide } from '../constants/Images';
import { makeStyles, Typography } from '@material-ui/core';

const Item = ({ img }) => {
    
    const [ height, setHeight ] = useState(300);
    const clasess = useStyles();

    useEffect(()=>{
        setHeight(window.innerHeight * 0.90 );
    },[])

    return( 
        <div style={{ backgroundImage: `url(${img.src})`, height: height, width:'100%' }} className="background-img">
            <h1 className={clasess.title}>
                ILUMINACIÓN <br/> {img.title}
            </h1>
        </div>)
};

const NumberPrev = ({ index, className }) => {
    return(
    <>
    <Typography variant="h6" className={className} gutterBottom style={{color:'white', fontFamily:'avant-regular'}}>{`${index}/`}</Typography>
    <Typography variant="h4" className={className} gutterBottom style={{color:'white'}}>{`${ImgSlide.length}`}</Typography>
    </>
    )
}

const SlideShow = () => {

    const [number, setNumber] = useState(1);

    return(
        <div id="home">
        <Carousel swipe={false} indicators={false} autoPlay={true}
            stopAutoPlayOnHover={false}
            swipe
            timeout={1000}
            interval={9000}
            PrevIcon={<NumberPrev index={number}/>}
            NextIcon={null}
            onChange={(active)=>setNumber(active + 1)}
            navButtonsProps={{
                style:{
                    backgroundColor:'transparent'
                }
            }}
        >
            {
                ImgSlide.map((img, index) => <Item img={img} key={index} />)
            }
        </Carousel>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    title:{
        padding: '20px', 
        fontSize: '32pt', 
        textAlign: 'center', 
        position: 'absolute', 
        bottom:"0px", 
        left:'7.5%',
        textTransform:'uppercase',
        margin:0,
        padding:'0',
        textAlign: 'left',
        color:'white',
        [theme.breakpoints.up('md')]:{
            fontSize: '64pt',
            bottom:"-27px", 
        }
        
    }
}));

export default SlideShow;
import industrial from '../images/industrial.png';
import comercial from '../images/comercial.png';
import vertical from '../images/vertical.png';
import urbano from '../images/urbano.png';

const services = [
    {
        id:1,
        icon:industrial,
        title:'Industrial',
        description: 'Área de aplicación: fábricas, patios, bodegas, empresas, grandes almacenes.',
    },
    {
        id:2,
        icon:comercial,
        title:'Comercial',
        description:'Área de aplicación: restaurantes, tiendas departamentales, plazas, locales, bares.',
    },
    {
        id:3,
        icon:vertical,
        title:'Vertical',
        description:'Área de aplicación: edificios, hoteles.'
    },
    {
        id:4,
        icon:urbano,
        title:'Urbano',
        description:'Área de aplicación: calles, parques, canchas.'
    }
];

export default services;
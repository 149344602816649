import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, makeStyles, Paper, Snackbar } from '@material-ui/core';
import sello from '../images/sello.png';
import bgContact from '../images/bgContact.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import Swal from 'sweetalert2'

const Contact = () => {
    const classes = useStyles();
    const [form, setForm] = useState({name: '', email:'', message:''});
    const [valid, setValid] = useState({ isValid: false, message:'', key:''});
    const [message, setMessage] = useState();
    const [open, setOpen] = useState(false);

    const handleOnChange = (event, key) =>
    {
        setForm(prev => ({...prev,[key]:event.target.value}));
    }

    const submit = () => {
        if(!validateForm()) return;

        let data = new FormData();
        data.append('name', form.name);
        data.append('email', form.email);
        data.append('message', form.message);

        axios.post('https://mpiluminacion.com/mail2.php', data)
        .then(({ data }) => { 
            if(data.status){
                Swal.fire({
                    icon:'success',
                    title: data.message
                });
                setForm({name: '', email:'', message:''});
                return;
            }

            Swal.fire({
                icon:'error',
                title:'Error',
                text: data.message
                
            })
        });
    }

    const validateForm =  () => {
        if(form.name.length == 0){
            setValid(prev => ({...prev, isValid:false, message: 'El nombre es obligatorio', key:'name'}));
            return false;
        }
        if(form.email.length == 0 || !validateEmail(form.email)){
            setValid(prev => ({...prev, isValid:false, message: 'El email es incorrecto', key:'email'}));
            return false
        }
        if(form.message.length == 0){
            setValid(prev => ({...prev, isValid:false, message: 'El mensaje es obligatorio', key:'message'}));
            return false
        }

        setValid(prev => ({...prev, isValid:true, message: '', key:''}));
        return true;
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return(
        <Grid container justify="center" alignItems="center" style={{ backgroundImage: `url(${bgContact})`, flexGrow:1 }} id="contacto">
            <Grid container xs={10} md={6}>
                <Paper className={classes.paper}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <h1>CONTACTO</h1>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.container}>
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel htmlFor="standard-adornment-amount">Nombre</InputLabel>
                                <Input
                                    error={valid.key === 'name'}
                                    value={form.name}
                                    onChange={(event)=>handleOnChange(event,'name')}
                                    id="standard-adornment-amount"
                                />
                                {
                                    !valid.isValid && valid.key === 'name' && <FormHelperText id="component-error-text">{valid.message}</FormHelperText>
                                }
                            </FormControl>
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel htmlFor="standard-adornment-amount" style={{marginTop: 10}}>Correo</InputLabel>
                                <Input
                                    error={valid.key === 'email'}
                                    value={form.email}
                                    onChange={(event)=>handleOnChange(event,'email')}
                                    id="standard-adornment"
                                />
                                {
                                    !valid.isValid && valid.key === 'email' && <FormHelperText id="component-error-text">{valid.message}</FormHelperText>
                                }
                            </FormControl>
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel htmlFor="standard-adornment-amount" style={{marginTop: 10}}>Mensaje</InputLabel>
                                <Input
                                    error={valid.key === 'message'}
                                    value={form.message}
                                    onChange={(event)=>handleOnChange(event,'message')}
                                    id="standard"
                                />
                                {
                                    !valid.isValid && valid.key === 'message' && <FormHelperText id="component-error-text">{valid.message}</FormHelperText>
                                }
                            </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10} style={{ textAlign:'center' }} className={classes.containerMobile}>
                            <Button variant="contained" className={classes.button}>ENVIAR</Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h3>COMUNICATE CON <br/> NOSOTROS</h3>
                            <p className={classes.txtBlue}>A través del teléfono: <br/>  <a className={classes.telLink} href="tel:3331902543">33 - 3190 - 2543</a></p>
                            <p className={classes.txtBlue}>O envianos un correo a: <br/> <a href="mailto:rmartinez@mpiluminacion.com">rmartinez@mpiluminacion.com</a></p>
                        </Grid>
                        <Grid item xs={12} sm={10} style={{ textAlign:'center' }} className={classes.containerDesktop}>
                            <Button variant="contained" className={classes.button} onClick={submit}>ENVIAR</Button>
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign:'center' }}>
                            <img className={classes.imgSello} src={sello} alt='sello'/>
                        </Grid>
                    </Grid>
                </Paper>
                <Grid item sm={12} xs={12} style={{ textAlign:'center'}}>
                    <ul className={classes.social}>
                        <li className={classes.elementSocial}><FacebookIcon/></li>
                        <li className={classes.elementSocial}><InstagramIcon/></li>
                        <li className={classes.elementSocial}><WhatsAppIcon/></li>
                    </ul>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper:{
        margin:'2rem 0rem',
        padding: '2rem',
        width: '100%',
        [theme.breakpoints.up('md')]:{
            marginBottom: '0rem',
            marginTop: '4rem'
        }
    },
    containerDesktop:{
        display:'none',
        [theme.breakpoints.up('md')]:{
            display: 'block',
        }
    },
    containerMobile:{
        display:'inline-flex',
        [theme.breakpoints.up('md')]:{
            display: 'none',
        }
    },
    container:{
        [theme.breakpoints.up('md')]:{
            paddingRight: '2rem',
        }
    },
    margin:{
        paddingTop:'1rem',
    },

    imgSello:{
        marginTop:'2rem',
        width:'100px',
        [theme.breakpoints.up('md')]:{
            marginTop:0,
            width:'70px'
        }
    },
    button:{
        width:'100%',
        height: '30px',
        borderRadius: '0.5rem',
        backgroundColor:'#06183c',
        color:'white',
        letterSpacing:'0.25rem',
        marginTop:'1rem',
        [theme.breakpoints.up('md')]:{
            maxWidth: '200px',
            marginTop:'2rem',
        }
    },
    txtBlue:{
        color:'#06183c'
    },
    telLink:{
        letterSpacing:'0.2rem',
        fontWeight:'bold',
    },
    social:{
        color:'white',
        display:'inline-flex',
        listStyle:'none',
    },
    elementSocial:{
        margin:'0rem 0.5rem',
    }
  }));

export default Contact;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Carousel from 'react-material-ui-carousel';


function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal({ handleOpen, handleClose, isOpen, isCarousel ,data = []}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
          {
              isCarousel ? (
                <div style={modalStyle} className={classes.paper}>
                <h2 id="simple-modal-title">{data[0]?.title}</h2>
                <Carousel
                    
                >
                    {
                        data.length > 0 && data[0].images.map((img, index) => (
                            <img src={img.src} alt={img.alt} key={index} width={'100%'}/>
                        ))
                    }
                </Carousel>  
              </div>
              ):(
                  null
              )
          }
      </Modal>
    </div>
  );
}

import { Grid } from '@material-ui/core';
import React from 'react';

const Services = ({ services = [] }) => {

    return (
        <Grid container style={{ paddingTop:'3rem', paddingBottom:'3rem' }}>
        {
            services.length > 0 && services.map((service, index) => (
                <Grid item sm={3} xs={12} key={index} style={{ textAlign:'center' }}>
                    <img src={service.icon} style={{ maxWidth:150 }} alt={service.title} />
                    <h3 style={{textAlign:'center', textTransform:'uppercase'}}>{service.title}</h3>
                    <p style={{textAlign:'center'}}>{service.description}</p>
                </Grid>
            ))
        }
        </Grid>
    );
}

export default Services;
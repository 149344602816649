import imgyears1 from '../images/6anios1.png';
import imgyears2 from '../images/6anios2.png';
import imgyears3 from '../images/6anios3.png';

const aboutUs = {
    title: 'MP ILUMINACIÓN',
    subtitle: "Somos una empresa 100% Mexicana y tapatía dedicada a ofrecer soluciones en iluminación para todo tipo de espacios. Brindando innovación, diseño y calidad en cada rincón.",
    fragmentDescription: '<p>Nuestra marca es comercializada por exclusivos distribuidores a nivel mundial. Por lo que nuestro servicio ha llegado a posicionarse de manera importante para brindar la mejor alternativa personalizada a cada cliente.</p> <p>Tenemos más de 6 años de experiencia en el mercado, en colaboración con diversos proyectos de alto nivel en impacto y calidad. Abarcando el ámbito industrial, comercial, vertical y urbano.</p>'
};

export const img6years = [
    {
        id: 1,
        alt: '6 anios',
        src: imgyears1,
    },
    {
        id: 2,
        alt: '6 anios',
        src: imgyears2,
    },
    {
        id: 3,
        alt: '6 anios',
        src: imgyears3,
    },
];

export default aboutUs;
import React, { useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SimpleModal from './ModalMp';
import { projectsImg } from '../constants/projects'

const Item = ({ image, onClick }) => {
    
    const classes = useStyles();

    return (
        <div style={{ position:'relative' }}>
            <img src={image.src} alt={image.alt} width={200}/>
            <div className={`${classes.imgBorder} ${classes.bgImage}`} onClick={()=> onClick(image.id)}>
                <h4>Ver proyecto <br/> Completo</h4>
            </div>
        </div>
    )
}

const SlideProject = ({ projects = [] }) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(1);
    const [active, setActive] = useState(2);
    const [data, setData] = useState([])

    const handleOpen = (id) => {
        const images = projectsImg.filter(project => project.id_project == id);
        setData(images);
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(()=>{
        setIsMobile(document.documentElement.clientWidth <= 769 ? 1 : 5)
    },[]);

    return (
        <div id="proyectos" style={{ paddingTop:'3rem', paddingBottom:'3rem' }}>
        <Carousel
        arrowLeft={<ChevronLeftIcon className={classes.Icon}/>}
        arrowRight={<ChevronRightIcon className={classes.Icon}/>}
        addArrowClickHandler
        centered
        slidesPerPage={isMobile}
        onChange={(value)=> setActive(value)}
        value={active}
        >
        {
            projects.map((project) => (
                <Item image={project} onClick={handleOpen}/>
            ))
        }
        </Carousel>
        <SimpleModal handleClose={handleClose} isOpen={open} isCarousel={true} data={data}/>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    Icon:{
        fontSize: '32pt',
        cursor: 'pointer'
    },
    bgImage:{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.5,
        height: '99%',
        width: '100%',
        transition: 'background-color .5s ease, color .5s ease, opacity .5s ease',
        backgroundColor: '#06183c',
        '& h4':{
            color: 'transparent',
            position: 'absolute',
            top: '50%',
            left:0,
            bottom:0,
            right:0,
            textAlign:'center'
        },
        '&:hover':{
            backgroundColor: 'transparent',
            border:'3px solid #ff8226',
            width: '98%',
            left: '0',
            opacity:1,
            height: '98%',
            '& h4':{
                color: 'white',
            },
        },
    },
    
}));

export default SlideProject;
const itemsNavigate = [
    {
        name:'Home',
        href:'#home'
    },
    {
        name:'Quiénes somos',
        href:'#quienes-somos'
    },
    {
        name:'Proyectos',
        href:'#proyectos'
    },
    {
        name:'Productos',
        href:'#productos'
    },
    {
        name:'Contacto',
        href:'#contacto'
    }
];

export default itemsNavigate;
import React, { useState } from 'react';
import { 
    AppBar, 
    IconButton, 
    List, 
    ListItem, 
    ListItemText, 
    makeStyles, 
    Toolbar, 
    Typography, 
    useScrollTrigger} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { TITLE_TEXT } from '../constants/appConstant';
import DrawerNavigation from './DrawerNavigation';
import { StaticImage } from 'gatsby-plugin-image';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Navigation = ({ position, logosrc, title, items = [], color, elevation }) => {

    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 10,
      });

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsOpen(open);
    }

    return(
        <AppBar position={position} style={{ backgroundColor: !trigger ? color : 'rgba(6, 24, 60, 0.7)' }} elevation={elevation}>
            <Toolbar variant="dense">
            <div className={classes.sectionMobile}>
                <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                >
                    <MenuIcon/>
                </IconButton>
                <DrawerNavigation 
                items={items} 
                open={isOpen} 
                onClose={toggleDrawer(false)}/>
            </div>
            <Typography variant="h6" color="inherit">
                {
                    logosrc ? (<StaticImage src={"../images/logoMP.png"} style={{ maxWidth:100 }} alt={title} />) : ( title )
                }
            </Typography>
            <div className={classes.grow} />
            <List className={classes.sectionDesktop}>
                {
                    items.length > 0 && items.map((item, index) => {
                        return <ListItem key={index} className={classes.containerList}>
                            <ListItemText 
                            primary={
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.textItem}
                            >
                                <AnchorLink to={item.href} className={classes.link}>
                                    <span>{item.name}</span>
                                </AnchorLink>
                            </Typography>} 
                            className={classes.itemList} />
                        </ListItem>
                    })
                }
            </List>
            </Toolbar>
        </AppBar>
    );
};

const useStyles = makeStyles((theme) => ({
    grow:{
        flexGrow:1
    },
    sectionDesktop:{
        display:'none',
        [theme.breakpoints.up('md')]:{
            display:'flex'
        },
        textAlign:'center',
    },
    sectionMobile:{
        display: 'flex',
        [theme.breakpoints.up('md')]:{
            display:'none',
        }
    },
    containerList:{
        minWidth:150,
        color:'white'
    },
    itemList:{
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    textItem:{
        fontSize: TITLE_TEXT,
        fontFamily:'avant-regular'
    },
    link:{
        color:'white',
        transition:'.5 ease',
        '&:hover':{
            color:'#ff8226'        
        },
    }
})) 

export default Navigation;
import altivez from '../images/altivez.png';
import berrylovers from "../images/berrylovers.png";
import calkinis from "../images/calkinis.png";
import canchas from "../images/canchas.png";
import panita from "../images/panita.png";
import panovo from "../images/panovo.jpg";
import proan from "../images/proan.png";
import themovecenter from '../images/themovecenter.png'
import tmc1 from '../images/tmc1.png'
import tmc2 from '../images/tmc2.png'
import tmc3 from '../images/tmc3.png'
import tmc4 from '../images/tmc4.png'
import proan1 from '../images/proan1.png'
import proan2 from '../images/proan2.png'
import proan3 from '../images/proan3.png'
import proan4 from '../images/proan4.png'
import panovo1 from '../images/panovo1.png'
import panovo2 from '../images/panovo2.png'
import panovo3 from '../images/panovo3.jpg'
import panita1 from '../images/panita1.png'
import panita2 from '../images/panita2.png'
import panita3 from '../images/panita3.png'
import panita4 from '../images/panita4.png'
import cancha1 from '../images/cancha1.png'
import cancha2 from '../images/cancha2.png'
import cancha3 from '../images/cancha3.png'
import cancha4 from '../images/cancha4.png'
import calkinis1 from '../images/calkinis1.png'
import calkinis2 from '../images/calkinis2.png'
import calkinis3 from '../images/calkinis3.png'
import calkinis4 from '../images/calkinis4.png'
import berrylover1 from '../images/berrylover1.png'
import berrylover2 from '../images/berrylover2.png'
import berrylover3 from '../images/berrylover3.png'
import berrylover4 from '../images/berrylover4.png'
import altivez1 from '../images/altivez1.png'
import altivez2 from '../images/altivez2.png'
import altivez3 from '../images/altivez3.png'
import altivez4 from '../images/altivez4.png'
import reacsa from '../images/reacsa.jpg'
import reacsa1 from '../images/reacsa1.png'
import reacsa2 from '../images/reacsa2.png'
import reacsa3 from '../images/reacsa3.png'
import reacsa4 from '../images/reacsa4.png'
import hyunday from '../images/hyunday.jpg'
import hyunday1 from '../images/hyunday1.jpg'
import hyunday2 from '../images/hyunday2.jpg'
import hyunday3 from '../images/hyunday3.jpg'
import ider from '../images/ider.jpg'
import ider1 from '../images/ider1.jpg'
import ider2 from '../images/ider2.jpg'
import ider3 from '../images/ider3.jpg'
import ider4 from '../images/ider4.jpg'
import puerta_providencia from '../images/puerta_providencia.jpg'
import puerta_providencia1 from '../images/puerta_providencia1.jpg'
import puerta_providencia2 from '../images/puerta_providencia2.jpg'
import puerta_providencia3 from '../images/puerta_providencia3.jpg'
import san_juan from '../images/san_juan.jpg'
import san_juan1 from '../images/san_juan1.jpg'
import yamaha from '../images/yamaha.jpg'
import yamaha1 from '../images/yamaha1.jpg'
import yamaha2 from '../images/yamaha2.jpg'
import yamaha3 from '../images/yamaha3.jpg'

export const projects = [
    {
        id: 1,
        src: altivez,
        alt: 'altivez',
        title: 'Altivez'
    },
/*     {
        id: 2,
        src: berrylovers,
        alt: 'Berry Lovers',
        title: 'Berry Lovers'
    }, */
    {
        id: 4,
        src: calkinis,
        alt: 'Calkinis',
        title: 'Calkinis'
    },
/*    {
        id: 5,
        src: canchas,
        alt: 'Canchas',
        title: 'Canchas'
    },
    {
        id: 6,
        src: panita,
        alt: 'Panita',
        title: 'Panita'
    },
    {
        id: 7,
        src: panovo,
        alt: 'Panovo',
        title: 'Panovo'
    }, */
    {
        id: 8,
        src: proan,
        alt: 'Proan',
        title: 'Proan'
    },
    {
        id: 9,
        src: themovecenter,
        alt: 'The move center',
        title: 'The Move Center'
    },
    {
        id: 10,
        src: reacsa,
        alt: 'Reacsa',
        title: 'Reacsa'
    },
    {
        id:11,
        src:hyunday,
        alt: 'Hyunday'
    },
    {
        id:12,
        src:ider,
        alt: 'Ider'
    },
    {
        id:13,
        src:puerta_providencia,
        alt: 'Puerta providencia'
    },
    {
        id:14,
        src:san_juan,
        alt: 'San Juan'
    },
    {
        id:15,
        src:yamaha,
        alt: 'yamaha'
    }
];

export const projectsImg = [
    {
        id: 1,
        id_project: 9,
        title:'THE MOVE CENTER',
        images:[
            {
                id:1,
                src: tmc1,
                alt: 'the move center 1',
            },
            {
                id:2,
                src: tmc2,
                alt: 'the move center 2',
            },
            {
                id:3,
                src: tmc3,
                alt: 'the move center 3',
            },
            {
                id:4,
                src: tmc4,
                alt: 'the move center 4',
            },
        ]
    },
    {
        id: 2,
        id_project: 8,
        title:'PROAN',
        images:[
            {
                id:1,
                src: proan1,
                alt: 'proan 1',
            },
            {
                id:2,
                src: proan2,
                alt: 'proan 2',
            },
            {
                id:3,
                src: proan3,
                alt: 'proan 3',
            },
            {
                id:4,
                src: proan4,
                alt: 'proan 4',
            },
        ]
    },
    {
        id: 3,
        id_project: 7,
        title:'PANOVO',
        images:[
            {
                id:1,
                src: panovo1,
                alt: 'panovo 1',
            },
            {
                id:2,
                src: panovo2,
                alt: 'panovo 2',
            },
            {
                id:3,
                src: panovo3,
                alt: 'panovo 3',
            },
        ]
    },
    {
        id: 4,
        id_project: 6,
        title:'PANITA',
        images:[
            {
                id:1,
                src: panita1,
                alt: 'panita 1',
            },
            {
                id:2,
                src: panita2,
                alt: 'panita 2',
            },
            {
                id:3,
                src: panita3,
                alt: 'panita 3',
            },
            {
                id:4,
                src: panita4,
                alt: 'panita 4',
            },
        ]
    },
    {
        id: 5,
        id_project: 5,
        title:'CANCHAS',
        images:[
            {
                id:1,
                src: cancha1,
                alt: 'cancha 1',
            },
            {
                id:2,
                src: cancha2,
                alt: 'cancha 2',
            },
            {
                id:3,
                src: cancha3,
                alt: 'cancha 3',
            },
            {
                id:4,
                src: cancha4,
                alt: 'cancha 4',
            },
        ]
    },
    {
        id: 6,
        id_project: 4,
        title:'CALKINIS',
        images:[
            {
                id:1,
                src: calkinis1,
                alt: 'calkinis 1',
            },
            {
                id:2,
                src: calkinis2,
                alt: 'calkinis 2',
            },
            {
                id:3,
                src: calkinis3,
                alt: 'calkinis 3',
            },
            {
                id:4,
                src: calkinis4,
                alt: 'calkinis 4',
            },
        ]
    },
    {
        id: 7,
        id_project: 2,
        title:'BERRY LOVERS',
        images:[
            {
                id:1,
                src: berrylover1,
                alt: 'berrylover 1',
            },
            {
                id:2,
                src: berrylover2,
                alt: 'berrylover 2',
            },
            {
                id:3,
                src: berrylover3,
                alt: 'berrylover 3',
            },
            {
                id:4,
                src: berrylover4,
                alt: 'berrylover 4',
            },
        ]
    },
    {
        id: 8,
        id_project: 1,
        title:'ALTIVEZ',
        images:[
            {
                id:1,
                src: altivez1,
                alt: 'altivez 1',
            },
            {
                id:2,
                src: altivez2,
                alt: 'altivez 2',
            },
            {
                id:3,
                src: altivez3,
                alt: 'altivez 3',
            },
            {
                id:4,
                src: altivez4,
                alt: 'altivez 4',
            },
        ]
    },
    {
        id: 9,
        id_project: 10,
        title:'REACSA',
        images:[
            {
                id:1,
                src: reacsa1,
                alt: 'reacsa 1',
            },
            {
                id:2,
                src: reacsa2,
                alt: 'reacsa 2',
            },
            {
                id:3,
                src: reacsa3,
                alt: 'reacsa 3',
            },
            {
                id:4,
                src: reacsa4,
                alt: 'reacsa 4',
            },
        ]
    },
    {
        id: 10,
        id_project: 11,
        title:'HYUNDAY',
        images:[
            {
                id:1,
                src: hyunday1,
                alt: 'hyunday 1',
            },
            {
                id:2,
                src: hyunday2,
                alt: 'hyunday 2',
            },
            {
                id:3,
                src: hyunday3,
                alt: 'hyunday 3',
            },
        ]
    },
    {
        id: 11,
        id_project: 12,
        title:'IDER',
        images:[
            {
                id:1,
                src: ider1,
                alt: 'ider 1',
            },
            {
                id:2,
                src: ider2,
                alt: 'ider 2',
            },
            {
                id:3,
                src: ider3,
                alt: 'ider 3',
            },
            {
                id:4,
                src: ider4,
                alt: 'ider 4',
            },
        ]
    },
    {
        id: 12,
        id_project: 13,
        title:'PUERTA PROVIDENCIA',
        images:[
            {
                id:1,
                src: puerta_providencia1,
                alt: 'puerta providencia 1',
            },
            {
                id:2,
                src: puerta_providencia2,
                alt: 'puerta providencia 2',
            },
            {
                id:3,
                src: puerta_providencia3,
                alt: 'puerta providencia 3',
            },
        ]
    },
    {
        id: 13,
        id_project: 14,
        title:'SAN JUAN',
        images:[
            {
                id:1,
                src: san_juan1,
                alt: 'san juan1',
            },
        ]
    },
    {
        id: 14,
        id_project: 15,
        title:'YAMAHA',
        images:[
            {
                id:1,
                src: yamaha1,
                alt: 'yamaha 1',
            },
            {
                id:2,
                src: yamaha2,
                alt: 'yamaha 2',
            },
            {
                id:3,
                src: yamaha3,
                alt: 'yamaha 3',
            },
        ]
    },
]